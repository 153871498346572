.cartModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    /* полупрозрачный фон */
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    z-index: 10001;
    /* больше, чем у лого */
    backdrop-filter: blur(2px);
    /* не обязательно, но красиво */
}

.cartModalContent {
    background-color: #fff;
    width: 400px;
    max-width: 90%;
    padding: 20px;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
    animation: slideIn 0.3s ease-out;
    display: flex;
    flex-direction: column;
    position: relative;
}

.cartModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.cartModalHeader h2 {
    margin: 0;
    font-size: 1.4rem;
}

.cartCloseButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.cartModalBody {
    flex-grow: 1;
    overflow-y: auto;
}

.cartEmpty {
    text-align: center;
    color: #888;
    font-style: italic;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.cartList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.cartItemPreview {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.cartItemImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.noImage {
    font-size: 12px;
    color: #888;
    text-align: center;
    padding: 5px;
}

.cartItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.cartCheckbox {
    transform: scale(1.2);
    margin-right: 5px;
}

.cartItemImage {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 8px;
    background-color: #f0f0f0;
}

.cartItemInfo {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.removeButton {
    background: none;
    border: none;
    font-size: 18px;
    color: #d00;
    cursor: pointer;
    margin-left: auto;
    transition: transform 0.2s;
}

.removeButton:hover {
    transform: scale(1.2);
}

.cartActionButtons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.cartTotal {
    margin-top: 10px;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
}

/* Самовывоз */
.selfPickupCheckbox {
    margin-top: 12px;
    background-color: #f9fafb;
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.selfPickupCheckbox label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.selfPickupCheckbox input[type="checkbox"] {
    width: 16px;
    height: 16px;
    accent-color: #111349;
    cursor: pointer;
}

.selfPickupNote {
    font-size: 13px;
    color: #777;
    margin-top: 6px;
}

.addressInput {
    padding: 10px 14px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 16px;
    font-size: 15px;
    outline: none;
    transition: border 0.2s;
    width: 92%;
}

.addressInput:focus {
    border-color: #111349;
}

@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    20%,
    60% {
        transform: translateX(-10px);
    }

    40%,
    80% {
        transform: translateX(10px);
    }
}

.addressInputError {
    animation: shake 0.5s linear;
    border-color: #ff6347;
}

.paySelectedButton {
    margin-top: 15px;
    background-color: #111349;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.paySelectedButton:hover {
    background-color: #0d0f3b;
}

.clearCartButton {
    margin-top: 15px;
    background-color: #d33;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.clearCartButton:hover {
    background-color: #a00;
}

.cartSectionDivider {
    margin: 30px 0;
    border: none;
    height: 1px;
    background-color: #eee;
}

.cartStatusSection {
    margin-top: 20px;
}

.cartStatusSection h4 {
    font-size: 16px;
    margin-bottom: 8px;
    color: #333;
}

.cartStatusNote {
    font-size: 13px;
    color: #666;
    margin-bottom: 10px;
}

.cartStatusList {
    list-style: disc;
    margin-left: 20px;
    color: #444;
    font-size: 14px;
}

.modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 10px;
    padding: 20px 30px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    position: relative;
    animation: fadeIn 0.3s ease;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.modalImage {
    width: 100%;
    border-radius: 8px;
    margin: 10px 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.modalBody {
    display: flex;
    gap: 20px;
    max-height: 80vh;
    overflow-y: auto;
}

.mediaWrapper {
    flex: 1;
    position: relative;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mediaItem {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: contain;
    border-radius: 8px;
}

.modalInfo {
    flex: 1;
    padding: 0 20px;
}

.modalInfo h2 {
    margin-top: 0;
    color: #333;
}

.modalInfo p {
    margin: 10px 0;
    line-height: 1.5;
}

.modalButtons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.modalClose {
    padding: 10px 20px;
    background-color: #111349;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.modalClose:hover {
    background-color: #0d0f3b;
}

@media (max-width: 768px) {
    .modalBody {
        flex-direction: column;
    }

    .mediaWrapper {
        min-width: auto;
    }
}