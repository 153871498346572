.container {
    padding: 24px;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.05);
}

/* Tabs */
.tabs {
    display: flex;
    border-bottom: 2px solid #eee;
    margin-bottom: 24px;
    overflow-x: auto;
    gap: 4px;
}

.tab,
.tabAdd {
    padding: 10px 20px;
    border: none;
    border-radius: 12px 12px 0 0;
    background-color: #f0f0f0;
    cursor: pointer;
    font-weight: 600;
    color: #333;
    transition: background 0.2s ease;
}

.tabAdd {
    background-color: #27ae60;
    color: white;
    font-size: 18px;
    flex-shrink: 0;
}

.active {
    background-color: #111349;
    color: white;
    box-shadow: 0 -2px 0 #111349 inset;
}

/* Content */
.content {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
}

.block {
    background-color: #f8f9fb;
    padding: 16px 20px;
    border-radius: 12px;
    font-size: 16px;
    min-width: 160px;
    flex: 1;
    text-align: center;
    color: #333;
    font-weight: 500;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.label {
    display: block;
    margin-bottom: 6px;
    font-weight: 500;
    color: #333;
}

.input {
    padding: 10px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    box-sizing: border-box;
    height: 42px;
}

.input:focus {
    outline: none;
    border-color: #111349;
    box-shadow: 0 0 0 2px rgba(17, 19, 73, 0.1);
}

.qrButton {
    background-color: #f2994a;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 12px;
    padding: 16px 28px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.2s ease;
}

.qrButton:hover {
    background-color: #e68a33;
}

.toggleDraft {
    margin-top: 12px;
    background: none;
    border: none;
    color: #0077cc;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    transition: 0.2s ease;
}

.draftSection {
    margin-top: 16px;
    border-top: 1px dashed #ccc;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.empty {
    color: #888;
    font-style: italic;
}

.productItem {
    background: #f9f9fb;
    padding: 12px 16px;
    border-radius: 12px;
    font-size: 14px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.addProduct {
    background-color: #111349;
    color: white;
    padding: 10px;
    font-size: 14px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    align-self: flex-start;
}

.productRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actions button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-left: 8px;
}

.discountSelector {
    margin-top: 6px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.discountSelector button {
    padding: 4px 10px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    cursor: pointer;
    background: #f2f2f2;
    transition: 0.2s ease;
}

.selectedDiscount {
    background-color: #111349;
    color: white;
    border-color: #111349;
}

.selectLabel {
    font-size: 14px;
    margin-top: 6px;
    display: block;
}

.select {
    width: 100%;
    padding: 6px 10px;
    font-size: 14px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.nameInput {
    font-size: 14px;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 100%;
    background-color: #fff;
    transition: 0.2s ease;
}

.nameInput:focus {
    outline: none;
    border-color: #111349;
    box-shadow: 0 0 0 2px rgba(17, 19, 73, 0.1);
}

.contentRow {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    align-items: center;
}

.totalLine {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #111349;
}

.productLine {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    flex-wrap: wrap;
}

.inlineInput {
    padding: 6px 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 120px;
    background-color: #fff;
}

.inlineSelect {
    padding: 6px 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 80px;
    background-color: #fff;
}

.finalPrice {
    font-weight: 600;
    color: #111349;
    width: 80px;
    text-align: right;
}

.switchWrapper {
    margin-top: 16px;
}

.switchLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    width: 400px;
    position: relative;
    padding: 8px 0;
}

.switchInput {
    opacity: 0;
    width: 0;
    height: 0;
}

.switchSlider {
    position: relative;
    width: 42px;
    height: 24px;
    background-color: #ccc;
    border-radius: 24px;
    cursor: pointer;
    transition: 0.2s;
}

.switchSlider::before {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    left: 3px;
    top: 3px;
    background-color: white;
    border-radius: 50%;
    transition: 0.2s;
}

.switchInput:checked+.switchSlider {
    background-color: #111349;
}

.switchInput:checked+.switchSlider::before {
    transform: translateX(18px);
}