.container {
    max-width: 1500px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: "Inter", sans-serif;
}

.title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 32px;
    color: #222;
}

/* ----- Тарифные карточки ----- */
.cardContainer {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding-bottom: 16px;
    scroll-snap-type: x mandatory;
}

.card {
    flex: 0 0 auto;
    min-width: 160px;
    max-width: 200px;
    padding: 20px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    text-align: center;
    scroll-snap-align: start;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: 2px solid transparent;
    box-sizing: border-box;
}

.card:hover {
    transform: scale(1.03);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

.selected {
    box-shadow:
        0 6px 18px rgba(0, 0, 0, 0.12),
        0 0 0 2px #111349 inset;
    /* 💡 внутренняя подсветка */
}

.amount {
    font-size: 20px;
    font-weight: 600;
    margin: 12px 0 4px;
    color: #333;
}

.ambassadors {
    font-size: 14px;
    color: #777;
}

/* ----- Область под карточками ----- */
.dropdownArea {
    background-color: #f5f7fa;
    border-radius: 16px;
    padding: 32px 24px;
    min-height: 180px;
    transition: all 0.3s ease;
}

.tariffTools {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
}

.remainingBox {
    background-color: #ffffff;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
    height: 100px;
    margin-bottom: 20px;
    margin-left: 560px;
}

.discountWrapper {
    flex: 1;
    min-width: 300px;
}

.label {
    font-size: 14px;
    color: #888;
    margin-bottom: 8px;
}

.amount {
    font-size: 24px;
    font-weight: 700;
    color: #111349;
}

.dropdownPlaceholder {
    color: #999;
    font-size: 16px;
    text-align: center;
}

.historySection {
    margin-top: 32px;
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.historySection h3 {
    margin-bottom: 16px;
    color: #111349;
    font-size: 20px;
    font-weight: 600;
}

.historyList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.historyItem {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    font-size: 15px;
    color: #333;
}

@media (max-width: 640px) {
    .tariffTools {
        flex-direction: column;
    }

    .remainingBox {
        width: 100%;
    }
}