.productCard {
    width: 200px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background: #f9f9f9;
    text-align: center;
    opacity: 0.9;
    cursor: pointer;
    position: relative;
    height: 580px;
}

.one {
    width: 200px;
    height: 250px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.one video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
}

.one:hover video {
    opacity: 1;
    pointer-events: auto;
}

.titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.productName {
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: #333;
    flex: 1;
    text-align: left;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.priceBlock {
    display: flex;
    align-items: baseline;
    gap: 4px;
    white-space: nowrap;
}

.price {
    font-size: 18px;
    font-weight: 600;
    color: #111;
}

.currency {
    font-size: 12px;
    color: #666;
}

.pricingSection {
    height: 120px;
    /* или подбери нужную тебе высоту */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.three {
    display: flex;
    font-size: 14px;
    font-weight: bold;
}

.priceSection {
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
}

.bankBadges {
    display: flex;
    gap: 0.3rem;
    flex-wrap: wrap;
}

.bankMini {
    background-color: #eeeeff;
    color: #333;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0.2rem 0.5rem;
    border-radius: 999px;
    white-space: nowrap;
    border: 1px solid #ccc;
}

.six {
    font-size: 12px;
    color: #777;
    padding: 5px;
    text-align: center;
}

.four {
    background-color: #111349;
    min-height: 70px;
    border-radius: 8px;
    padding: 0.5rem 0.75rem;
    margin-top: 0.5rem;
    color: #fff;
}

.four_none {
    min-height: 70px;
    border-radius: 8px;
    padding: 0.5rem 0.75rem;
    margin-top: 0.5rem;
    color: #fff;
}

.installmentPlaceholder {
    font-size: 0.75rem;
    font-style: italic;
    color: #888;
    opacity: 0.6;
}

.installmentsTitle {
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
    color: #aaa;
}

.installmentsList {
    display: flex;
    gap: 0.4rem;
    flex-wrap: wrap;
}

.installment {
    background-color: #2b2f74;
    color: #bcbcbd;
    border: 1px solid #ffda6b55;
    padding: 0.2rem 0.6rem;
    border-radius: 999px;
    font-size: 0.75rem;
    font-weight: 500;
    white-space: nowrap;
}

.five {
    font-size: 12px;
    text-align: center;
    color: #555;
    margin-top: 10px;
}

.legalDiscount,
.legalDiscountUnavailable {
    min-height: 40px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-size: 0.85rem;
    padding: 0.4rem 0.75rem;
    margin: 0.5rem 0;
}

/* Активный промокод */
.legalDiscount {
    background-color: #e5f7ed;
    color: #2d7a50;
    font-weight: 500;
}

/* Нет промокода */
.legalDiscountUnavailable {
    background-color: #f0f0f0;
    color: #888;
    font-style: italic;
}

.sellerTypeLine {
    font-size: 13px;
    color: #555;
    margin-top: 4px;
}

.footer_card {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}


.product_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.watchCount,
.publishedAt {
    font-size: 14px;
    color: #666;
}