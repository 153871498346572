.container_authSignup {
    display: flex;
    justify-content: center;
    z-index: -10;
}

.container_authSignup_inner {
    position: relative;
    display: block;
    background: #111349;
    padding: 20px;
    opacity: 0.9;
    color: #ffffff;
    z-index: 10;
    margin-top: 100px;
    width: 400px;
}

.container_authSignup_inner_form {
    max-width: 400px;
    /* Ограничение ширины формы */
    margin: 0 auto;
    /* Центровка формы на странице */
    display: flex;
    flex-direction: column;
    /* Вертикальное расположение элементов */
    box-sizing: border-box;
    /* Учитываем padding в ширине */
}

.authsignup_input {
    width: 100%;
    /* Элементы занимают всю ширину контейнера */
    box-sizing: border-box;
    /* Учитываем padding и border в ширине */
    margin: 10px 0;
    padding: 10px;
}

.authsignup_input_file {
    width: 100%;
    /* Элементы занимают всю ширину контейнера */
    box-sizing: border-box;
    /* Учитываем padding и border в ширине */
    margin: 10px 0;
    padding: 10px;
}

.formGroup {
    margin-bottom: 15px;
    position: relative;
}

.authsignup_checkboxLabel {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.authsignup_checkbox {
    margin-right: 10px;
}

.submitButton {
    padding: 10px 15px;
    font-size: 16px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.submitButton:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.submitButton:hover:not(:disabled) {
    background-color: #0056b3;
}




/* registration */
.authorization {

    color: #111349;
    position: absolute;
    margin-top: 60px;
    width: 135px;
    height: 20px;
    margin-left: 905px;

}

#error-message {
    color: red;
    font-weight: bold;
    display: none;
}

.field-error {
    color: red;
    font-size: 0.9em;
    display: none;
    /* Ошибки изначально скрыты */
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}






/* modal */
.modal-form {
    max-width: 400px;
    margin: auto;
    background: #000000;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-form-group {
    margin-bottom: 15px;
    position: relative;
}


.modal-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.toggle-visibility {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 16px;
}

.error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.modal-button {
    padding: 10px 15px;
    font-size: 16px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:disabled {
    background: #ccc;
}




/* Ссылка для открытия модального окна */
.open-modal {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Общие стили для модального окна */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    /* Скрываем по умолчанию */
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

/* Показ модального окна при переходе по ссылке */
#modal:target {
    display: flex;
    /* Показываем модальное окно */
}

/* Содержимое модального окна */
.modal-content {
    position: relative;
    background: white;
    padding: 20px;
    max-width: 500px;
    max-height: 70vh;
    /* Ограничение по высоте */
    overflow-y: auto;
    /* Включаем прокрутку при переполнении */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Для скрытия модального окна по клику вне */
.modal:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: -1;
}









button {
    background-color: #c7c7c7;
    color: #111349;
    cursor: pointer;
}

/* 
button:hover {
    background-color: #111349;
    color: #c7c7c7;

} */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: rgb(0, 0, 0);
    color: white;
    padding: 20px;
    width: 400px;
    text-align: center;
}

.modal-content input,
.modal-content button {
    margin: 10px 0;
    width: 80%;
}

.hidden {
    display: none;
}



/* Боковая панель и бургер для мобильных устройств */
@media only screen and (max-width: 768px) {

    .authorization {

        color: #111349;
        position: absolute;
        margin-top: 60px;
        width: 135px;
        height: 20px;
        margin-left: 5px;
    }

    .video {
        display: flex;
        justify-content: center;
        margin-right: 90%;
        width: auto;
        height: 100vh;
    }

    header {
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        margin-top: 60px;
        background: #fff;
        transform: translateX(100%);
        transition: transform 0.3s ease;
        z-index: 100;
        padding: 1rem;
    }

    header.open {
        transform: translateX(0);
    }

    .logo-container {
        margin-bottom: 1rem;
    }

    header nav ul {
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }

    header nav ul li a {
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        margin: 0;
    }

    /* Стили для бургера */
    .burger {
        display: block;
        position: fixed;
        top: 1rem;
        right: 1rem;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        z-index: 101;
    }

    .burger span {
        display: block;
        width: 100%;
        height: 2px;
        background: #c7c7c7;
        margin: 15px 0;
        transition: all 0.3s ease;
        transform: rotate(-60deg);
    }

    .burger span:nth-child(1) {
        position: absolute;
        margin-top: 15px;
        margin-left: -10px;

    }

    .burger span:nth-child(2) {
        position: absolute;
        margin-left: -5px;
    }

    .burger span:nth-child(3) {
        position: absolute;
        /* margin-top: 15px; */

    }

    .burger.open span:nth-child(1) {
        transform: translateY(8px) rotate(50deg);
        background-color: #c7c7c7;
        transition: all .7s;
        position: absolute;
        top: -10px;
        margin-left: 5;
    }

    .burger.open span:nth-child(2) {
        opacity: 0;
    }

    .burger.open span:nth-child(3) {
        transform: translateY(-8px) rotate(-50deg);
        background-color: #c7c7c7;
        transition: all .1s;
    }

    .saved-text {
        flex-direction: column;
        width: 283px;
    }














    .container {
        flex-direction: column;
        gap: 15px;
    }

    .balance-box {
        font-size: 20px;
        padding: 10px;
    }

    select,
    input,
    button {
        font-size: 14px;
        padding: 8px;
    }

    .transactions table th,
    .transactions table td {
        font-size: 12px;
        padding: 8px;
    }
}

@media (max-width: 450px) {

    .transactions table th,
    .transactions table td {
        font-size: 8px;
        padding: 3px;
    }

    button {
        font-size: 10px;
        padding: 6px;
    }
}