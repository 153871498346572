.modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    padding: 1rem;
}

.modal {
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.96);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.modalInput {
    width: 100%;
    padding: 0.6rem 0.75rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 0.95rem;
    margin-top: 0.5rem;
    margin-bottom: 1.2rem;
}

.fieldHint {
    font-size: 0.85rem;
    color: #666;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    line-height: 1.4;
}

.codeInputs {
    display: flex;
    gap: 0.75rem;
    justify-content: center;
    margin-bottom: 1.2rem;
}

.codeInput {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #ccc;
    transition: border 0.2s ease;
}

.codeInput:focus {
    border-color: #111349;
    outline: none;
}

.actionsRow {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
}

.submitBtn {
    background-color: #111349;
    color: white;
    border: none;
    padding: 0.6rem 1.2rem;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.submitBtn:hover {
    background-color: #191e59;
}

.clearBtn {
    background-color: transparent;
    color: #333;
    border: 1px solid #ccc;
    padding: 0.6rem 1.2rem;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.clearBtn:hover {
    background-color: #f1f1f1;
}

.stepList {
    margin: 0.5rem 0 1.2rem;
    padding-left: 1.2rem;
    font-size: 0.85rem;
    color: #666;
    line-height: 1.5;
}

.progressBar {
    width: 100%;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
    margin-top: 0.5rem;
    overflow: hidden;
}

.progressInner {
    height: 100%;
    background-color: #111349;
    transition: width 1s linear;
}