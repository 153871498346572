/* Общий контейнер */
.mainContainer {
    display: flex;
    justify-content: center;
    z-index: -10;
}

.contentContainer {
    width: 1000px;
    display: block;
    position: relative;
    background: #111349;
    padding: 20px;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 87%;
    opacity: 0.9;
    margin-top: 50px;
    color: #ffffff;
    z-index: 1;
}

/* Стили дерева */
/* Ось X (горизонтальная линия снизу) */
.axisX {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #ccc;
}

/* Ось Y (вертикальная линия слева) */
.axisY {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: #ccc;
}

/* Стрелка на конце оси X (вправо) */
.arrowX {
    position: absolute;
    bottom: -5px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid #ccc;
}

/* Стрелка на конце оси Y (вверх) */
.arrowY {
    position: absolute;
    top: -10px;
    left: -5px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid #ccc;
}

.treeContainer {
    position: relative;
    overflow: visible;
    /* вообще не обрезает ничего */
    white-space: nowrap;
    border: 1px solid #ccc;
    padding: 10px;
}

.leftTeethWrapper {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0;
    pointer-events: none;
}

.leftTooth {
    width: 2px;
    height: 6px;
    background-color: #ccc;
    margin: 2px auto;
}

.treeBlock {
    margin-left: 20px;
    position: relative;
}

.tree {
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: start;
}

.tree ul {
    display: flex;
    flex-direction: column-reverse;
    list-style: none;
    padding-left: 40px;
    margin-left: 20px;
    border-left: 1px solid #ccc;
}

.treeItem {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    cursor: pointer;
}

.treeItemContent {
    display: flex;
    align-items: center;
    /* Выравнивает элементы по центру вертикально */
    margin-right: 10px;
    /* Отступ справа, если это нужно */
}

.spanItem {
    width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    background-color: #111349;
    border-radius: 4px;
    color: white;
}


.spanItem:hover {
    background-color: #ddd;
    color: #111349;
}

.hidden {
    display: none;
}

/* Медиа-правила */
@media only screen and (max-width: 768px) {
    .contentContainer {
        flex-direction: column;
        gap: 15px;
    }

    .treeContainer {
        padding: 10px;
    }
}

@media (max-width: 450px) {
    .spanItem {
        font-size: 12px;
        padding: 3px;
    }
}