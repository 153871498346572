.panel_width {
    width: 100%;
}

.filter_panel {
    margin-top: 30px;
    padding: 20px;
    background-color: #111349;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    color: white;
    opacity: 0.9;
}

.filter_panel_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.filterHeaderLeft {
    display: flex;
    align-items: baseline;
    gap: 10px;
}

.toggleButton {
    background: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 15px;
    transition: transform 0.3s ease;
    opacity: 0.9;
}

.cartButton {
    background-color: #111349;
    color: white;
    border: none;
    padding: 8px 18px;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.cartButton:hover {
    background-color: #0d0f3b;
}

h3 {
    font-size: 22px;
    font-weight: bold;
}

.filter_panel_menu {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.filter_panel_body {
    width: 100%;
    margin-top: 30px;
    background-color: #111349;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    color: white;
    opacity: 0.9;
}

.category_item {
    background-color: white;
    color: #111349;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.active {
    background-color: #111349;
    color: white;
}

.filter_panel_menuChoices {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.panel_menChoices_shaffle {
    background-color: white;
    color: #111349;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.activeSubcategory {
    background-color: #111349;
    color: white;
}

.panel_buttons {
    margin-top: 60px;
    display: flex;
    gap: 15px;
    justify-content: center;
}

.panel_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    background-color: white;
    color: #111349;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.panel_button:hover {
    background-color: #e0e0e0;
}

.container {
    margin-top: 40px;
    display: flex;
    gap: 40px;
}

.product_search {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 80px;
    margin-bottom: 20px;
}

.product_searchInput {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    height: 30px;
}

.product_searchButton {
    background-color: rgba(17, 19, 73, 0.9);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.product_searchButton:hover {
    background-color: #0d0f3b;
}

.productsList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    flex-grow: 1;
}

.sideBar {
    width: 450px;
    min-height: 100px;
    border: 2px solid #ddd;
    padding: 15px;
    background-color: rgba(249, 249, 249, 0.9);
}

/* Адаптивность для планшетов */
@media (max-width: 768px) {
    .container {
        margin-top: 40px;
        display: flex;
        gap: 5px;
    }

    .product_search {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        margin-left: 30px;
    }

    .product_searchButton {
        padding: 5px 15px;
        height: 40px;
        font-size: 16px;
    }

    .sideBar {
        font-size: 15px;
    }
}