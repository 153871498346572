.card {
    background: white;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.statusBadge {
    background-color: #c8e6c9;
    color: #256029;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 600;
}

.tokenDropdown {
    background: #f5faff;
    border-radius: 8px;
    padding: 12px 16px;
    border-left: 4px solid #2196f3;
    margin-bottom: 12px;
}

.tokenHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.tokenHeader code {
    background: #e3f2fd;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 13px;
}

.tokenLine {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}

.copyButton {
    padding: 2px 8px;
    font-size: 13px;
    background-color: #e0e0e0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.copyButton:hover {
    background-color: #d0d0d0;
}

.arrow {
    font-size: 16px;
    color: #2196f3;
}

.tokenContent {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
}

.tokenContent code {
    background: #e3f2fd;
    padding: 2px 5px;
    border-radius: 4px;
}

.tokenContent .note {
    background: #fff3cd;
    border-left: 4px solid #ffb300;
    padding: 10px;
    border-radius: 6px;
    color: #5c3c00;
    margin-top: 10px;
    font-size: 13px;
}

/* Данные по deliveryDataInputs */
.deliveryDataInputs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    padding: 16px;
    background-color: #f5f8fa;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.input {
    padding: 10px 14px;
    border-radius: 6px;
    width: 50%;
    border: 1px solid #ccc;
    font-size: 14px;
    transition: border-color 0.2s;
    outline: none;
}

.input:focus {
    border-color: #1976d2;
    background-color: #fff;
}

.inputFile {
    padding: 8px 10px;
    border: 1px dashed #999;
    background-color: #fafafa;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.inputFile:hover {
    border-color: #1976d2;
}

.select {
    padding: 10px 12px;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 14px;
    background-color: #fff;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg ... %3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
}

.select:focus {
    border-color: #1976d2;
}

/* Данные по deliveryDataInputs */
.deliveryDataInputs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    padding: 16px;
    background-color: #f5f8fa;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.input {
    padding: 10px 14px;
    border-radius: 6px;
    width: 50%;
    border: 1px solid #ccc;
    font-size: 14px;
    transition: border-color 0.2s;
    outline: none;
}

.input:focus {
    border-color: #1976d2;
    background-color: #fff;
}

.inputFile {
    padding: 8px 10px;
    border: 1px dashed #999;
    background-color: #fafafa;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.inputFile:hover {
    border-color: #1976d2;
}

.select {
    padding: 10px 12px;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 14px;
    background-color: #fff;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg ... %3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
}

.select:focus {
    border-color: #1976d2;
}

/* Данные по deliveryDataInputs */
.deliveryDataInputs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    padding: 16px;
    background-color: #f5f8fa;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.input {
    padding: 10px 14px;
    border-radius: 6px;
    width: 50%;
    border: 1px solid #ccc;
    font-size: 14px;
    transition: border-color 0.2s;
    outline: none;
}

.input:focus {
    border-color: #1976d2;
    background-color: #fff;
}

.inputFile {
    padding: 8px 10px;
    border: 1px dashed #999;
    background-color: #fafafa;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.inputFile:hover {
    border-color: #1976d2;
}

.select {
    padding: 10px 12px;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 14px;
    background-color: #fff;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg ... %3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
}

.select:focus {
    border-color: #1976d2;
}

/* Данные по deliveryDataInputs */
/* -- END --  */

.orderInfo p {
    margin: 3px 0;
    font-size: 14px;
    color: #333;
}

.paymentInfo {
    font-size: 14px;
    background: #e3f2fd;
    padding: 12px 14px;
    border-left: 4px solid #2196f3;
    border-radius: 8px;
    line-height: 1.6;
    color: #0d47a1;
}

.paymentInfo strong {
    font-weight: 600;
}

.paymentInfo {
    font-size: 14px;
    background: #e3f2fd;
    padding: 10px 14px;
    border-left: 4px solid #2196f3;
    border-radius: 8px;
    line-height: 1.6;
    color: #0d47a1;
}

.note {
    background: #fffde7;
    border-left: 4px solid #ffb300;
    border-radius: 8px;
    padding: 10px 14px;
    font-size: 13px;
    color: #5c3c00;
    line-height: 1.4;
}

.actions {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.actions input[type="file"] {
    font-size: 14px;
}

.actions button {
    padding: 10px 16px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.actions button:hover {
    background-color: #125aa0;
}