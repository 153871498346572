.pageWrapper {
    display: flex;
    gap: 24px;
    padding: 24px;
}

.page {
    flex: 1;
    max-width: 600px;
}

.mapContainer {
    flex: 1 1;
    position: sticky;
    top: 24px;
    min-width: 400px;
    height: 600px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
}

/* Страница курьера */
.page {
    max-width: 600px;
    padding: 24px;
    background-color: #f9fafc;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    font-family: 'Segoe UI', sans-serif;
}

.title {
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
}

.tokenSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 32px;
}

.tokenSection input {
    padding: 10px 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
}

.tokenSection button {
    padding: 10px;
    background-color: #111349;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.tokenSection button:hover {
    background-color: #2a2e85;
}

.deliveriesSection {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.deliveryCard {
    background-color: #ffffff;
    border-radius: 10px;
    border-left: 4px solid #4a90e2;
    padding: 16px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.deliveryCard:hover {
    background-color: #f1f4ff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
}

.deliveryCustomer {
    padding-bottom: 12px;
    border-bottom: 1px dashed #ccc;
    margin-bottom: 12px;
}

.deliverySeller {
    background-color: #f4f6fb;
    padding: 12px;
    border-radius: 6px;
    border-left: 4px solid #4a90e2;
    font-size: 14px;
    color: #333;
}

.deliverySeller p {
    margin: 4px 0;
}

.deliveryCard p strong {
    color: #111349;
}

.deliveryCard .earnings {
    color: #28a745;
    font-weight: bold;
}

.deliveryCard p {
    margin: 4px 0;
    font-size: 14px;
}

.code {
    margin-top: 10px;
    font-weight: bold;
    color: #111349;
    font-size: 16px;
}

.codeInput {
    margin-top: 6px;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: -webkit-fill-available;
    margin-bottom: 10px;
}

.confirmButton {
    padding: 8px 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
}

.confirmButton:hover {
    background-color: #218838;
}

.confirmed {
    margin-top: 10px;
    color: #155724;
    font-weight: bold;
    font-size: 14px;
}