.orderFlowWrapper {
    background-color: #f9fafe;
    border: 1px solid #e2e6f0;
    border-radius: 12px;
    padding: 24px;
    width: 86%;
    margin: 0 auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    font-family: 'Segoe UI', sans-serif;
}

/* Статусная линия */
.statusLine {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    position: relative;
}

.statusLine::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 5%;
    right: 5%;
    height: 4px;
    background-color: #e0e0e0;
    z-index: 0;
    border-radius: 4px;
    transform: translateY(-50%);
}

.statusStep {
    z-index: 1;
    background-color: #ffffff;
    padding: 8px 16px;
    border-radius: 20px;
    border: 2px solid #d0d0d0;
    color: #777;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.statusStep+.statusStep {
    margin-left: 8px;
}

.statusStep:hover {
    background-color: #f0f0f0;
}

.statusStep.active {
    background-color: #111349;
    color: white;
    border-color: #111349;
    box-shadow: 0 0 10px rgba(17, 19, 73, 0.3);
}
    
.statusStep.done {
    background-color: #d0e7ff;
    color: #111349;
}

.statusStep.disabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
}

/* Контент по статусу */
.statusContent h3 {
    font-size: 20px;
    margin-bottom: 8px;
}

.statusContent p {
    font-size: 15px;
    margin: 8px 0;
}

/* Кнопки */
.buttonRow {
    display: flex;
    gap: 12px;
    margin: 16px 0;
}

.acceptBtn,
.rejectBtn {
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    border: none;
    transition: background-color 0.2s ease;
}

.acceptBtn {
    background-color: #28a745;
    color: white;
}

.acceptBtn:hover {
    background-color: #218838;
}

.rejectBtn {
    background-color: #dc3545;
    color: white;
}

.rejectBtn:hover {
    background-color: #c82333;
}

/* Блок курьера */
.courierBlock {
    background-color: #eef2ff;
    border-left: 4px solid #4a5cff;
    padding: 12px 16px;
    border-radius: 8px;
    margin-top: 16px;
}

.inputCode {
    width: 100%;
    padding: 8px 12px;
    margin-top: 8px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
}

.returnBtn {
    background-color: #ff8c00;
    color: white;
    border: none;
    padding: 10px 16px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    margin-top: 16px;
    cursor: pointer;
}

.returnBtn:hover {
    background-color: #e67e00;
}

.declined {
    color: #dc3545;
    font-weight: bold;
}

.expandSection {
    margin-top: 16px;
    padding: 12px 16px;
    background-color: #f6f8ff;
    border-radius: 8px;
    border-left: 4px solid #4a5cff;
}

.expandBtn {
    margin-top: 8px;
    background: none;
    border: none;
    color: #111349;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
}

.photoSection {
    margin-top: 12px;
}

.packagePhoto {
    width: 100%;
    max-width: 300px;
    border-radius: 6px;
    margin: 8px 0;
}

.noteBox {
    background-color: #fff5d6;
    border-left: 4px solid #ffc107;
    padding: 10px 14px;
    border-radius: 8px;
    margin: 16px 0;
    font-size: 14px;
    color: #555;
}

.success {
    color: #28a745;
    font-weight: bold;
    margin-bottom: 8px;
}

.deliveryDataInputs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    padding: 16px;
    background-color: #f5f8fa;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.labeledInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    font-size: 14px;
    color: #333;
}

.input {
    padding: 10px 14px;
    border-radius: 6px;
    width: 50%;
    border: 1px solid #ccc;
    font-size: 14px;
    transition: border-color 0.2s;
    outline: none;
}

.input:focus {
    border-color: #1976d2;
    background-color: #fff;
}

.inputFile {
    padding: 8px 10px;
    border: 1px dashed #999;
    background-color: #fafafa;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.inputFile:hover {
    border-color: #1976d2;
}

.select {
    padding: 10px 12px;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 14px;
    background-color: #fff;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg ... %3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
}

.select:focus {
    border-color: #1976d2;
}