.subsubheading  {
    margin: 0;
    color: #111349;
}

.counterContainer {
    flex-direction: column;
    /* Располагаем элементы вертикально */
    align-items: center;
    /* Центрируем по горизонтали */
    gap: 8px;
    /* Отступ между счётчиком и текстом */
}

.counterInput {
    width: 60px;
    /* Сделает поле уже */
    text-align: center;
    /* Выравнивает текст по центру */
    border: none;
    /* Убирает границу */
    outline: none;
    /* Убирает фокусную рамку */
    background: transparent;
    /* Убирает фон */
    font-size: 16px;
    /* Сделает текст читабельным */
    color: white;
}

.mailingContainer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: 15%;
}

.inputContainer {
    opacity: 0.9;
    position: relative;
    top: 27px;
    width: 300px;
    padding: 10px;
}

textarea {
    display: block;
    background-color: #111349;
    width: 100%;
    height: 140px;
    padding: 10px;
    font-size: 16px;
    color: #ffffff;
    border: none;
    transition: all 0.1s ease;
}

textarea::placeholder {
    color: #ffffff;
    font-weight: 100;
}

/* Стили для OptionBox с эффектом hover */
.optionBox {
    display: flex;
    background-color: #111349;
    margin-top: 5px;
    width: 94%;
    padding: 10px;
    align-items: center;
    color: #ffffff;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.saveBox {
    justify-content: center;
    display: flex;
    background-color: #111349;
    margin-top: 5px;
    width: 94%;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    color: #ffffff;
    position: relative;
    cursor: pointer;
}

.saveBox:hover {
    background-color: #c7c7c7;
    color: #111349;
}

.saveButton {
    cursor: pointer;
}

.paymentBox {
    justify-content: center;
    display: flex;
    background-color: #111349;
    margin-top: 5px;
    width: 94%;
    padding: 10px;
    align-items: center;
    color: #ffffff;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.paymentBox:hover {
    background-color: #c7c7c7;
    color: #111349;
}

.payment {
    display: flex;
}

.payment_text {
    display: inline-table;
    margin-right: 10px;
}

.payment_input {
    border: 1px solid #ccc;
    font-size: 16px;
    width: 80px;
    padding: 5px;
    border-radius: 5px;
}

.label {
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.icon {
    width: 15px;
    padding-right: 5px;
}

.number_input {
    border: 1px solid #ccc;
    font-size: 16px;
    width: 180px;
    padding: 5px;
    border-radius: 5px;
}

.numberText {
    color: white;
    font-size: 12px;
}

/* Контейнер для выпадающего списка */
.dropdownContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}

/* Кнопка выбора */
.dropdownButton {
    width: 100%;
    background: none;
    color: white;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    text-align: left;
    background-color: #111349;
}

.dropdownButton:hover {
    background-color: #c7c7c7;
    color: #111349;
}

/* Скрытый список */
.dropdownList {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #c7c7c7;
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 150px;
    z-index: 1000;
    border: 1px solid #ccc;
    max-height: 200px;
    /* Ограничение высоты */
    overflow-y: auto;
    /* Добавляем скролл */
    scrollbar-width: thin;
    /* Для Firefox */
    scrollbar-color: #111349 #c7c7c7;
}

/* Элементы списка */
.dropdownList li {
    padding: 10px;
    color: #111349;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* Эффект наведения */
.dropdownList li:hover {
    background-color: #111349;
    color: #ffffff;
}

.dropdownContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}



/* Стили для модального окна */
/* Переключатель режима ввода */
.switchButtons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
}

.switchButton {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    transition: background-color 0.3s;
}

.switchButton:hover {
    background-color: #ddd;
}

.switchButton.active {
    background-color: #111349;
    color: white;
}

/* Файл-инпут */
.fileInput {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.fileName {
    margin-top: 10px;
    font-weight: bold;
}

/* Основные стили модального окна */
.warningText {
    color: red;
    font-weight: bold;
    font-size: 25px;
}

.modelDropdownContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}

/* Кнопка выбора */
.modelDropdownButton {
    width: 100%;
    background: none;
    color: white;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    text-align: left;
    background-color: #111349;
}

.modelDropdownButton:hover {
    background-color: #c7c7c7;
    color: #111349;
}

/* Открытый список */
.modelDropdownList {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #c7c7c7;
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 150px;
    z-index: 1000;
    border: 1px solid #ccc;
    max-height: 200px;
    /* Ограничение высоты */
    overflow-y: auto;
    /* Скролл при переполнении */
}

/* Элементы списка */
.modelDropdownList li {
    padding: 10px;
    color: #111349;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* Эффект наведения */
.modelDropdownList li:hover {
    background-color: #111349;
    color: #ffffff;
}

/* Показываем список при наведении */
.modelDropdownContainer:hover .modelDropdownList {
    display: block;
}

.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    border-radius: 10px;
}

.modalContent h2 {
    margin-top: 10px;
}

textarea {
    width: 96%;
    height: 100px;
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
}

.uploadButton {
    background-color: #111349;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 10px;
}

.uploadButton:hover {
    background-color: #0e103f;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}





/* Маленькое модальное окно */
.modalContent.small {
    width: 300px;
    padding: 15px;
}

/* Блоки сервисов */
.serviceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.serviceBlocks {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    margin-top: 40px;
    justify-content: center;
}

.serviceBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.serviceLogo {
    width: 200px;
    height: 50px;
    padding: 10px;
}

.counterBlock {
    justify-content: space-around;
    color: #ffffff;
    min-width: 200px;
    max-width: 200px;
    padding: 10px;
    text-align: center;
    background: #111349;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
}

.counterButton {
    margin: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    background-color: #111349;
    color: white;
    border: none;
}

.counter {
    font-size: 24px;
    margin: 10px 0;
}

.costText {
    color: #ffffff;
}

.savedTextContainer {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 1000px;
    opacity: 0.9;
}

.savedText {
    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    background-color: #111349;
    color: #c7c7c7;
    width: 660px;
}

.savedText span {
    flex: 1;
    margin-right: 10px;
}

.editButton {
    margin: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    background-color: #111349;
    color: white;
    border: none;
}

.deleteButton {
    margin: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    background-color: #111349;
    color: white;
    border: none;
}

@media only screen and (max-width: 768px) {
    .savedText {
        width: 370px;        
    }
    
    .mailingContainer {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-left: none;
    }

    .serviceContainer {
        width: 300px;
    }  
}