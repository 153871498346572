/* Колокол */
.headerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.iconWrapper {
    position: relative;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.iconsLine {
    display: flex;
    gap: 15px;
}

.bell_icon {
    font-size: 20px;
    line-height: 1;
}

.thunder_icon_wrapper {
    display: flex;
    position: relative;
}

.thunder_icon_1 {
    position: absolute;
    left: 0;
    z-index: 10;
}

.thunder_icon_2 {
    position: absolute;
    left: 10px;
}

.thunder_icon_3 {
    position: absolute;
    left: -10px;
}

.modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 24px;
    /* убрал animation, т.к. скролл может конфликтовать */
    overflow-y: auto;
}

.modalContent {
    background-color: #fff;
    border-radius: 16px;
    padding: 24px 32px;
    width: 100%;
    max-width: 900px;
    max-height: 90vh;
    /* 👈 ограничим высоту модалки */
    overflow-y: auto;
    /* 👈 если контент не помещается — скролл */
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.25);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    position: relative;
}

.modalContent::-webkit-scrollbar {
    width: 8px;
}

.modalContent::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.instructions {
    background: #f0f8ff;
    border-left: 4px solid #1976d2;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    color: #333;
    line-height: 1.6;
    max-height: 300px;
    overflow-y: auto;
}

.instructions summary {
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    margin-bottom: 8px;
    color: #1976d2;
}

.instructions ol {
    padding-left: 20px;
    margin: 0;
}

.instructions ul {
    padding-left: 20px;
    margin: 8px 0 0;
    list-style-type: disc;
}

.instructions p {
    margin: 8px 0;
}

.instructions code {
    background: #e3f2fd;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 13px;
}

.tabMenu {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    flex-wrap: wrap;
}

.tabMenu button {
    padding: 8px 14px;
    background: #f0f0f0;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.2s ease;
}

.tabMenu button:hover {
    background: #e0e0e0;
}

.activeTab {
    background-color: #1976d2;
    color: white;
}

.tabContent {
    max-height: 60vh;
    /* Ограничим высоту — можно подстроить */
    overflow-y: auto;
    /* Вертикальный скролл, если много заказов */
    padding-right: 8px;
    /* Чтобы не налезал скролл на контент */
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 16px;
    background-color: #fafafa;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
}

.tabContent::-webkit-scrollbar {
    width: 6px;
}

.tabContent::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
}

.tabContent::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 10px;
}

.modalContent h2 {
    margin-top: 0;
    font-size: 24px;
    margin-bottom: 16px;
    color: #333;
}

.modalContent span {
    display: block;
    margin-bottom: 8px;
    color: #444;
    font-size: 16px;
}

.userTable {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
}

.headerTableRow {
    display: grid;
    grid-template-columns:
        120px 130px 120px 200px 80px 110px 550px;
    align-items: center;
    padding: 10px 16px;
    border-radius: 8px;
    background-color: #f4f4f4;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    gap: 8px;
}

.userRow {
    display: grid;
    grid-template-columns:
        120px
        /* Логин */
        130px
        /* Телефон */
        120px
        /* Город */
        0.6fr
        /* Адрес (растягивается) */
        60px
        /* Кол-во */
        110px
        /* Статус */
        90px
        /* Отменить */
        90px
        /* Детали */
        80px
        /* Счёт */
        100px;
    /* Комиссия */
    align-items: center;
    padding: 10px 16px;
    border-radius: 8px;
    background-color: #f4f4f4;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    gap: 8px;
}

.headerTableRow {
    font-weight: bold;
    background-color: #e0e0e0;
    color: #333;
}

.cell,
.headerCell {
    font-size: 14px;
    color: #333;
    word-break: break-word;
}

.cancelButton {
    background-color: #111349;
    color: white;
    border: none;
    padding: 10px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-top: 40px;
    margin-bottom: 40px;
}

.cancelButton:hover {
    background-color: #d9363e;
}

.paymentButton {
    margin-left: 100px;
    background-color: #111349;
    width: 150px;
    color: white;
    border: none;
    padding: 10px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.paymentButton:hover {
    background-color: #d9363e;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.notificationBadge {
    position: absolute;
    top: -5px;
    right: -5px;
    background: red;
    color: white;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    padding: 2px 5px;
    line-height: 1;
    min-width: 16px;
    text-align: center;
}


/* Контекст */
.savedTextContainer {
    width: -webkit-fill-available;
    background: #111349;
    color: white;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    /* 👈 важное свойство */
    cursor: pointer;
    transition: background 0.3s ease, box-shadow 0.3s ease;
}

.savedTextContainer:hover {
    background: #1c1e5e;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.savedTextContainerContent {
    display: flex;
    flex-direction: column;
}

.previewTitle {
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
}

.actionFooter {
    display: flex;
    /* Горизонтальное расположение кнопок */
    justify-content: flex-end;
    /* Кнопки прижаты к правому краю */
    gap: 10px;
    /* Отступ между кнопками */
    margin-top: 15px;
    /* Отступ сверху от блока с деталями */
}

.editButton,
.deleteButton {
    background: transparent;
    border-radius: 5px;
    padding: 4px 8px;
    font-size: 13px;
    color: #ffffff;
    cursor: pointer;
    transition: background 0.2s ease, border-color 0.2s ease;
}

.editButton:hover {
    background: #111349;
}

.deleteButton:hover {
    background: #943c3c;
    border-color: #ff4a4a;
}

.details {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.5;
    color: #ddd;
}

@media only screen and (max-width: 768px) {
    .headerRow {
        display: block;
    }

    .headerTableRow,
    .userRow {
        grid-template-columns: 70px 90px 70px 120px 0.7fr;
    }

    .modalContent {
        padding: 12px 10px;
        width: 100%;
    }

    .modalContent span {
        font-size: 10px;
    }
}

.priceInput {
    padding: 6px 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.commission {
    font-size: 13px;
    color: #555;
    white-space: nowrap;
}