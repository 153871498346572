.modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    padding: 1rem;
}

.modal {
    background-color: #fff;
    border-radius: 12px;
    padding: 2rem;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.96);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.subheading {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    color: #111;
    text-align: center;
}

.fieldGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.fieldGroup label {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 0.4rem;
}

.input {
    padding: 0.6rem 0.75rem;
    font-size: 0.95rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.2s ease;
}

.input:focus {
    border-color: #111349;
    outline: none;
}

.submitBtn {
    width: 100%;
    background-color: #111349;
    color: #fff;
    border: none;
    padding: 0.6rem 1.2rem;
    border-radius: 8px;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.passwordWrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.passwordWrapper input {
    flex: 1;
    padding-right: 2.5rem;
}

.eyeBtn {
    position: absolute;
    right: 0.5rem;
    background: none;
    border: none;
    font-size: 1.1rem;
    cursor: pointer;
    color: #555;
    transition: color 0.2s;
}

.eyeBtn:hover {
    color: #111;
}

.eyeIcon {
    font-size: 1.25rem;
    cursor: pointer;
    position: absolute;
    right: 0.6rem;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
    color: #555;
}

.eyeLine {
    position: relative;
}

.eyeLine::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: red;
    transform: rotate(-20deg);
}