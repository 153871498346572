.chatWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
}

.chatButton {
    padding: 8px 16px;
    background-color: #111349;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 6px;
    width: 100%;
    transition: background 0.2s ease;
}

.chatButton:hover {
    background-color: #0d0f3b;
}

.chatLabel {
    font-size: 13px;
    color: #444;
    font-weight: 500;
    margin: 0;
    text-align: center;
}