.card {
    background: #fff;
    border-radius: 10px;
    padding: 18px 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.orderInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.orderInfo p {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.disabledNote {
    font-size: 13px;
    color: #c0392b;
    margin-top: 8px;
}

.invoiceInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.invoiceInput input {
    max-width: 200px;
    padding: 8px 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
}

.bankLabel {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 4px;
    color: #333;
}

.bankOptions {
    display: flex;
    gap: 12px;
    align-items: center;
}

.bankOption {
    display: flex;
    align-items: center;
    gap: 6px;
    background: #f8f8f8;
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s ease;
    border: 1px solid transparent;
}

.bankOption input[type="radio"] {
    accent-color: #111349;
    transform: scale(1.1);
}

.bankOption img {
    width: 48px;
    height: auto;
}

.bankOption:hover {
    background-color: #eef0fa;
}

.paymentCheckbox {
    display: flex;
    font-weight: 500;
    color: #333;
    user-select: none;
}

.paymentCheckbox input[type="checkbox"] {
    accent-color: #111349;
    transform: scale(1.1);
    cursor: pointer;
}

.submitButton {
    width: fit-content;
    padding: 8px 16px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.submitButton:hover {
    background-color: #1565c0;
}

.commissionWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    color: #444;
}

.commissionWrapper label {
    min-width: 130px;
    font-weight: 500;
}

.commissionInput {
    width: 120px;
    padding: 6px 10px;
    border: 1px solid #ccc;
    background-color: #f2f2f2;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
}

.note {
    font-size: 13px;
    color: #444;
    line-height: 1.6;
    background-color: #e3f2fd;
    padding: 12px 14px;
    border-left: 4px solid #2196f3;
    border-radius: 8px;
}

.chatButton {
    padding: 8px 16px;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    /* Полная ширина для большей заметности */
}

.chatButton:hover {
    background-color: #0056b3;
}