.card {
    background: white;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.header h4 {
    margin: 0;
    font-size: 16px;
    color: #b71c1c;
}

.badge {
    background-color: #ffebee;
    color: #c62828;
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 600;
}

.orderInfo p {
    margin: 4px 0;
    font-size: 14px;
    color: #333;
}

.note {
    background: #fff3cd;
    border-left: 4px solid #ffb300;
    border-radius: 8px;
    padding: 10px 14px;
    font-size: 13px;
    color: #5c3c00;
    line-height: 1.4;
}

.buttonRow {
    display: flex;
    justify-content: end;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.actions {
    display: flex;
    justify-content: flex-end;
}

.actions button {
    padding: 10px 16px;
    background: #c62828;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.2s ease;
}

.actions button:hover {
    background: #b71c1c;
}

.policy {
    background: #e3f2fd;
    border-left: 4px solid #2196f3;
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 13px;
    color: #0d47a1;
    line-height: 1.5;
}

.policy p {
    margin: 8px 0;
}