.sidebar {
    width: 280px;
    background-color: #f4f6f8;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto;
}

.sidebar h3 {
    margin-bottom: 16px;
    font-size: 18px;
    color: #1976d2;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.searchInput {
    width: 100%;
    padding: 8px 10px;
    font-size: 14px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.avatar {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #1976d2;
    color: white;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unreadDot {
    position: absolute;
    top: -3px;
    right: -3px;
    width: 10px;
    height: 10px;
    background: red;
    border: 2px solid #fff;
    border-radius: 50%;
}

.chatItem {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 10px 12px;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.2s ease;
}

.chatItem:hover {
    background-color: #e3f2fd;
}

.active {
    background-color: #bbdefb;
    box-shadow: inset 0 0 0 2px #1976d2;
}

.avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #1976d2;
    color: white;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chatInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.status {
    font-size: 12px;
    margin-top: 2px;
    font-weight: 500;
    border-radius: 6px;
    padding: 2px 6px;
    width: fit-content;
}

.status.waiting {
    background-color: #fff3cd;
    color: #856404;
}

.status.shipping {
    background-color: #d1ecf1;
    color: #0c5460;
}

.status.success {
    background-color: #d4edda;
    color: #155724;
}

.status.default {
    background-color: #eeeeee;
    color: #444;
}

.roleText {
    font-size: 12px;
    color: #888;
    margin-top: 2px;
    display: block;
}