.wrapper {
    margin-top: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.list {
    max-width: 1200px;
    width: 100%;
}

.inviteGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
    margin-top: 20px;
}

.card {
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.avatar {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.name {
    font-weight: 600;
    font-size: 14px;
}

.stars {
    color: gold;
    font-size: 16px;
    letter-spacing: 2px;
}

.cardButton {
    margin-top: 8px;
    padding: 8px 12px;
    font-size: 13px;
    font-weight: 500;
    color: white;
    background-color: #111349;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.2s ease;
}

.cardButton:hover {
    background-color: #1a1d66;
}

.modalButtons {
    display: flex;
    justify-content: center;
    gap: 12px;
}

.modalButtons button:first-child {
    background-color: #111349;
    color: white;
}

.modalButtons button:last-child {
    background-color: #eee;
    color: #111;
}